<template>
  <div class="flex flex-col gap-8" :key="renderKey">
    <StatsCalculatedAt :calculatedAt="calculatedAt" />

    <UILoading v-if="loading || loadingVergelijk"></UILoading>
    <template v-else>
      <div class="card">
        <h2>Ritten - Dagwaarde</h2>
        <div class="grid xl:grid-cols-5 gap-4">
          <BamTarget
            v-for="timeframe in Object.keys(statistieken)"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="getWaarde(statistieken[timeframe])"
            :change="props.filtersVergelijken ? usePercentageIncrease(getWaarde(statistieken[timeframe]), getWaarde(statistiekenVergelijk[timeframe])) : undefined"
            :bam="bams.omzet[timeframe]"
            :target="targets.omzet[timeframe]"
            currency
          >
            <UIAlertInfo tag="p">
              <small>Op basis van <b>vertrek/retour datum</b></small>
            </UIAlertInfo>

            <template v-slot:suffix>
              <ul class="mt-2 pt-2 border-t border-dashboard-dark">
                <li class="flex flex-row justify-between">
                  <div>
                    <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].dagwaarde_bevestigd) }} Bevestigde waarde</span>
                    <small class="block pl-4">&euro;&nbsp;{{ formatPrice(statistieken[timeframe].gegeven_korting) }} Korting</small>
                    <small class="block pl-4">&euro;&nbsp;{{ formatPrice(statistieken[timeframe].gegeven_korting_promotie) }} Waardebon/Promotie</small>
                  </div>
                </li>
                <li class="flex flex-row justify-between">
                  <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].dagwaarde_annulatiekosten) }} Annulatiekosten</span>
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>

      <div class="card">
        <h2>Ritten - Aantal</h2>
        <div class="grid xl:grid-cols-5 gap-4">
          <BamTarget
            v-for="timeframe in Object.keys(statistieken)"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="statistieken[timeframe].aantal_ritten"
          >
            <template v-slot:prefix>
              <ul>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].aantal_ritten }} Enkele ritten</span>
                  <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].aantal_ritten" :previous-value="statistiekenVergelijk[timeframe].aantal_ritten" />
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>

      <div class="card">
        <h2>Ritten - Kinderen</h2>
        <div class="grid xl:grid-cols-5 gap-4">
          <BamTarget
            v-for="timeframe in Object.keys(statistieken)"
            :key="timeframe"
            :title="capitalize(timeframe)"
            :value="statistieken[timeframe].met_kinderen"
          >
            <template v-slot:prefix>
              <ul>
                <li class="flex flex-row justify-between">
                  <span>{{ statistieken[timeframe].met_kinderen }} Ritten met kinderen</span>
                </li>
              </ul>
            </template>
            <template v-slot:suffix>
              <ul class="pt-2 border-t border-dashboard-dark">
                <li class="flex flex-col justify-between">
                  <span>{{ statistieken[timeframe].met_kinderstoelen }} Ritten met kinderstoelen</span>
                  <span>{{ statistieken[timeframe].met_kinderzitjes }} Ritten met kinderzitjes</span>
                  <span>{{ statistieken[timeframe].met_maxicosis }} Ritten met maxicosis</span>
                </li>
              </ul>
            </template>
          </BamTarget>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { formatPrice } from '@/functions/formatNumber'
import { capitalize } from '@/functions/formatText'

import BamTarget from '@/components/Charts/BamTarget.vue'
import UILoading from '@/components/UI/Loading.vue'

import UIAlertInfo from '@/components/UI/Text/AlertInfo.vue'
import StatsCalculatedAt from '@/components/Stats/CalculatedAt.vue'
import usePercentageIncrease from '@/hooks/usePercentageIncrease'

import { useMarketingBamTargets, useMarketingValues } from '@/pages/stats/Dashboard2/useMarketingStats'
import useRenderKey from '@/hooks/useRenderKey'

const PercentageChange = defineAsyncComponent(() => import('@/components/UI/Labels/PercentageChange.vue'))

const props = defineProps({
  filters: Object,
  filtersVergelijken: Object,
  renderKey: [String, Number],
})

useRenderKey(props.renderKey)

const apiData = computed(() => ({ ...(props.filters || {})  }))
const apiDataVergelijk = computed(() => props.filtersVergelijken || null)

const {
  bams,
  targets,
} = useMarketingBamTargets({
  year: apiData.value?.year,
  month: apiData.value?.month,
  avg_price: props.filters?.avg_price,
})

const {
  loading,
  calculatedAt,
  statistieken,
} = useMarketingValues({
  apiData: apiData,
  endpoint: 'STATS_OMZET_RITTEN',
})

const {
  loading: loadingVergelijk,
  statistieken: statistiekenVergelijk,
} = useMarketingValues({
  apiData: apiDataVergelijk,
  endpoint: 'STATS_OMZET_RITTEN',
})

const getWaarde = (obj) => Math.max(obj?.dagwaarde_bevestigd, 0) + Math.max(obj?.dagwaarde_annulatiekosten, 0)
</script>
